<template>
  <v-container class="container--fluid grid-list-md">
    <v-row no-gutters>
      <v-col cols="12">
        <v-card class="mx-auto mb-5" flat>
          <v-card-title class="pa-0 mb-2 primary--text">
            <v-icon color="primary"
              >mdi-file-delimited-outline</v-icon
            >
            <h6 class="ms-2 font-weight-regular">
              {{ $t("miscellaneous.relativeNumber") }}
            </h6>
          </v-card-title>
          <v-divider />
          <v-card-text class="pa-0 px-2" v-if="complusoryNumbers">
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-col class="text-right">
                  <v-btn
                    v-if="accessRight.includes('create') || accessRight.includes('edit')"
                    class="mt-2 mb-2 justify-end"
                    color="primary"
                    small
                    :loading="loading"
                    :disabled="!valid"
                    @click="saveComplusoryNumbers"
                  >
                    {{ $t("miscellaneous.save") }}
                  </v-btn>
                </v-col>
              </v-row>
              <v-divider />
              <v-row>
                <v-col cols="12">
                  <v-stepper class="pb-0" v-model="e6" vertical non-linear flat>
                    <v-stepper-step
                      class="py-3 px-4 text-h6"
                      :complete="e6 > 1"
                      editable
                      @click.native="accordionStepper(e6)"
                      step="1"
                      :rules="[
                        (value) => (model.vat_credit_debit ? model.vat_credit_debit.length <= 15 : true),
                        (value) => (model.balance_cancelation ? model.balance_cancelation.length <= 15 : true),
                        (value) => (model.vat_credit_debit ? /^[0-9]*$/.test(model.vat_credit_debit) : true),
                        (value) => (model.balance_cancelation ? /^[0-9]*$/.test(model.balance_cancelation) : true),
                      ]"
                    >
                    <h6 class="primary--text font-weight-regular">
                      {{ $t("miscellaneous.basicNumbers") }}
                    </h6>
                    </v-stepper-step>
                    <v-stepper-content
                      step="1"
                      class="ml-7 mb-auto pb-0 mr-3 py-2 px-4"
                    >
                      <v-row>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            hide-details="auto"
                            :label="$t('miscellaneous.vatDebitCredit')"
                            v-model.trim="model.vat_credit_debit"
                            :rules="vatDebitCreditRequired"
                          />
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            hide-details="auto"
                            v-model.trim="model.balance_cancelation"
                            :label="$t('miscellaneous.balanceCancelation')"
                            :rules="balanceCancelationRequired"
                          />
                        </v-col>
                      </v-row>
                      <v-row class="ma-0 my-2">
                        <v-col cols="12" class="text-center pa-0">
                          <v-btn
                            disabled
                            class="ma-2"
                            outlined
                            fab
                            x-small
                            color="primary"
                          >
                            <v-icon>mdi-chevron-double-up</v-icon>
                          </v-btn>
                          <v-btn
                            class="ma-2"
                            outlined
                            fab
                            x-small
                            color="primary"
                            @click="e6 = 2"
                          >
                            <v-icon>mdi-chevron-double-down</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-stepper-content>
                    <v-stepper-step
                      class="py-3 px-4 text-h6"
                      :complete="e6 > 2"
                      editable
                      @click.native="accordionStepper(e6)"
                      step="2"
                      :rules="[
                        (value) => (model.prefix_for_employee ? model.prefix_for_employee.length <= 15 : true),
                        (value) => (model.prefix_for_customer ? model.prefix_for_customer.length <= 15 : true),
                        (value) => (model.prefix_for_supplier ? model.prefix_for_supplier.length <= 15 : true),
                        (value) => (model.prefix_for_employee ? /^[0-9]*$/.test(model.prefix_for_employee) : true),
                        (value) => (model.prefix_for_customer ? /^[0-9]*$/.test(model.prefix_for_customer) : true),
                        (value) => (model.prefix_for_supplier ? /^[0-9]*$/.test(model.prefix_for_supplier) : true),
                      ]"
                    >
                      <h6 class="primary--text font-weight-regular">
                        {{ $t("miscellaneous.prefixNumbers") }}
                      </h6>
                    </v-stepper-step>
                    <v-stepper-content
                      step="2"
                      class="ml-7 mb-auto pb-0 mr-3 py-2 px-4"
                    >
                      <v-row>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            hide-details="auto"
                            v-model.trim="model.prefix_for_employee"
                            :label="$t('miscellaneous.employeePrefix')"
                            :rules="employeePrefixRequired"
                          />
                          <v-text-field
                            hide-details="auto"
                            v-model.trim="model.prefix_for_customer"
                            :label="$t('miscellaneous.customerPrefix')"
                            :rules="customerPrefixRequired"
                          />
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            hide-details="auto"
                            v-model.trim="model.prefix_for_supplier"
                            :label="
                              $t('miscellaneous.supplierPrefix')
                            "
                            :rules="supplierPrefixRequired"
                          />
                        </v-col>
                      </v-row>
                      <v-row class="ma-0 my-2">
                        <v-col cols="12" class="text-center pa-0">
                          <v-btn
                            class="ma-2"
                            outlined
                            fab
                            x-small
                            color="primary"
                            @click="e6 = 1"
                          >
                            <v-icon>mdi-chevron-double-up</v-icon>
                          </v-btn>
                          <v-btn
                            class="ma-2"
                            outlined
                            fab
                            x-small
                            color="primary"
                            @click="e6 = 3"
                          >
                            <v-icon>mdi-chevron-double-down</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-stepper-content>
                    <v-stepper-step
                      class="py-3 px-4 text-h6"
                      :complete="e6 > 3"
                      editable
                      @click.native="accordionStepper(e6)"
                      step="3"
                    >
                      <h6 class="primary--text font-weight-regular">
                        {{ $t("miscellaneous.referenceDates") }}
                      </h6>
                    </v-stepper-step>
                    <v-stepper-content
                      step="3"
                      class="ml-7 mb-auto pb-0 mr-3 py-2 px-4"
                    >
                      <v-row>
                        <v-col cols="12" sm="6">
                          <v-menu
                            ref="startDateMenu"
                            v-model="startDateMenu"
                            :close-on-content-click="false"
                            :return-value.sync="formattedStartDate"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                hide-details="auto"
                                v-model="model.reference_date_limit_from"
                                :label="$t('miscellaneous.referenceDateLimitFrom')"
                                @blur="formattedStartDate = profileById.date_format ? parseDateYYYYMMDD(model.reference_date_limit_from) : model.reference_date_limit_from"
                                clearable
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="formattedStartDate"
                              @change="
                                $refs.startDateMenu.save(formattedStartDate)
                              "
                            >
                              <v-btn small class="primary" @click="$refs.startDateMenu.save(todayDate())">{{ $t('route.today') }}</v-btn>
                            </v-date-picker>
                          </v-menu>
                          <v-menu
                            ref="endDateMenu"
                            v-model="endDateMenu"
                            :close-on-content-click="false"
                            :return-value.sync="formattedEndDate"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                hide-details="auto"
                                v-model="model.reference_date_limit_untill"
                                clearable
                                :label="$t('miscellaneous.referenceDateLimitUntill')"
                                @blur="formattedEndDate = profileById.date_format ? parseDateYYYYMMDD(model.reference_date_limit_untill) : model.reference_date_limit_untill"
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              :min="
                                formattedStartDate ? formattedStartDate : ''
                              "
                              v-model="formattedEndDate"
                              @change="$refs.endDateMenu.save(formattedEndDate)"
                            >
                              <v-btn small class="primary" @click="$refs.endDateMenu.save(todayDate())">{{ $t('route.today') }}</v-btn>
                            </v-date-picker>
                          </v-menu>
                          <v-menu
                            ref="startDateMenu2"
                            v-model="startDateMenu2"
                            :close-on-content-click="false"
                            :return-value.sync="formattedStartDateFor2"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                hide-details="auto"
                                v-model="model.reference_two_date_limit_from"
                                clearable
                                :label="$t('miscellaneous.referenceTwoDateLimitFrom')"
                                @blur="formattedStartDateFor2 = profileById.date_format ? parseDateYYYYMMDD(model.reference_two_date_limit_from) : model.reference_two_date_limit_from"
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="formattedStartDateFor2"
                              @change="
                                $refs.startDateMenu2.save(formattedStartDateFor2)
                              "
                            >
                              <v-btn small class="primary" @click="$refs.startDateMenu2.save(todayDate())">{{ $t('route.today') }}</v-btn>
                            </v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-menu
                            ref="endDateMenu2"
                            v-model="endDateMenu2"
                            :close-on-content-click="false"
                            :return-value.sync="formattedEndDateFor2"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                hide-details="auto"
                                v-model="model.reference_two_date_limit_untill"
                                clearable
                                :label="$t('miscellaneous.referenceTwoDateLimitUntill')"
                                @blur="formattedEndDateFor2 = profileById.date_format ? parseDateYYYYMMDD(model.reference_two_date_limit_untill) : model.reference_two_date_limit_untill"
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              :min="
                                formattedStartDateFor2 ? formattedStartDateFor2 : ''
                              "
                              v-model="formattedEndDateFor2"
                              @change="$refs.endDateMenu2.save(formattedEndDateFor2)"
                            >
                              <v-btn small class="primary" @click="$refs.endDateMenu2.save(todayDate())">{{ $t('route.today') }}</v-btn>
                            </v-date-picker>
                          </v-menu>
                          <v-menu
                            ref="startDateMenu3"
                            v-model="startDateMenu3"
                            :close-on-content-click="false"
                            :return-value.sync="formattedStartDateFor3"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                hide-details="auto"
                                v-model="model.bank_notes_date_limit_from"
                                clearable
                                :label="$t('miscellaneous.bankNotesDateLimitFrom')"
                                @blur="formattedStartDateFor3 = profileById.date_format ? parseDateYYYYMMDD(model.bank_notes_date_limit_from) : model.bank_notes_date_limit_from"
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="formattedStartDateFor3"
                              @change="
                                $refs.startDateMenu3.save(formattedStartDateFor3)
                              "
                            >
                                <v-btn small class="primary" @click="$refs.startDateMenu3.save(todayDate())">{{ $t('route.today') }}</v-btn>
                            </v-date-picker>
                          </v-menu>
                          <v-menu
                            ref="endDateMenu3"
                            v-model="endDateMenu3"
                            :close-on-content-click="false"
                            :return-value.sync="formattedEndDateFor3"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                hide-details="auto"
                                v-model="model.bank_notes_date_limit_untill"
                                clearable
                                :label="$t('miscellaneous.bankNotesDateLimitUntill')"
                                @blur="formattedEndDateFor3 = profileById.date_format ? parseDateYYYYMMDD(model.bank_notes_date_limit_untill) : model.bank_notes_date_limit_untill"
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              :min="
                                formattedStartDateFor3 ? formattedStartDateFor3 : ''
                              "
                              v-model="formattedEndDateFor3"
                              @change="$refs.endDateMenu3.save(formattedEndDateFor3)"
                            >
                              <v-btn small class="primary" @click="$refs.endDateMenu3.save(todayDate())">{{ $t('route.today') }}</v-btn>
                            </v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                      <v-row class="ma-0 my-2">
                        <v-col cols="12" class="text-center pa-0">
                          <v-btn
                            class="ma-2"
                            outlined
                            fab
                            x-small
                            color="primary"
                            @click="e6 = 2"
                          >
                            <v-icon>mdi-chevron-double-up</v-icon>
                          </v-btn>
                          <v-btn
                            disabled
                            class="ma-2"
                            outlined
                            fab
                            x-small
                            color="primary"
                          >
                            <v-icon>mdi-chevron-double-down</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-stepper-content>
                  </v-stepper>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { formatDateDDMMYYYY, parseDateYYYYMMDD, todayDate } from "../../../utils";

export default {
  name: "Miscellaneous",
  data() {
    return {
      loading: false,
      model: {},
      previousStep: 1,
      e6: 1,
      valid: false,
      startDateMenu: false,
      startDateMenu2: false,
      startDateMenu3: false,
      endDateMenu: false,
      endDateMenu2: false,
      endDateMenu3: false,
      formattedStartDate: "",
      formattedStartDateFor2: "",
      formattedStartDateFor3: "",
      formattedEndDate: "",
      formattedEndDateFor2: "",
      formattedEndDateFor3: "",
    };
  },
  computed: {
    ...mapGetters({
      accessRight: "accessRight",
      complusoryNumbers: "miscellaneous/complusoryNumbers",
      profileById: 'profile/profileById'
    }),
    vatDebitCreditRequired() {
      return [
        (v) => !v || /^[0-9]*$/.test(v) || this.$t("formRules.integerValue"),
        (v) =>
          !v ||
          (v && v.length <= 15) ||
          this.$t("formRules.lengthValidMessage", {
            fieldName: this.$t("miscellaneous.vatDebitCredit"),
            type: this.$t("common.less"),
            size: 15,
          }),
      ];
    },
    balanceCancelationRequired() {
      return [
        (v) => !v || /^[0-9]*$/.test(v) || this.$t("formRules.integerValue"),
        (v) =>
          !v ||
          (v && v.length <= 15) ||
          this.$t("formRules.lengthValidMessage", {
            fieldName: this.$t("miscellaneous.balanceCancelation"),
            type: this.$t("common.less"),
            size: 15,
          }),
      ];
    },
    employeePrefixRequired() {
      return [
        (v) => !v || /^[0-9]*$/.test(v) || this.$t("formRules.integerValue"),
        (v) =>
          !v ||
          (v && v.length <= 15) ||
          this.$t("formRules.lengthValidMessage", {
            fieldName: this.$t("miscellaneous.employeePrefix"),
            type: this.$t("common.less"),
            size: 15,
          }),
      ];
    },
    customerPrefixRequired() {
      return [
        (v) => !v || /^[0-9]*$/.test(v) || this.$t("formRules.integerValue"),
        (v) =>
          !v ||
          (v && v.length <= 15) ||
          this.$t("formRules.lengthValidMessage", {
            fieldName: this.$t("miscellaneous.customerPrefix"),
            type: this.$t("common.less"),
            size: 15,
          }),
      ];
    },
    supplierPrefixRequired() {
      return [
        (v) => !v || /^[0-9]*$/.test(v) || this.$t("formRules.integerValue"),
        (v) =>
          !v ||
          (v && v.length <= 15) ||
          this.$t("formRules.lengthValidMessage", {
            fieldName: this.$t("miscellaneous.supplierPrefix"),
            type: this.$t("common.less"),
            size: 15,
          }),
      ];
    },
  },
  watch: {
    complusoryNumbers: {
      handler: function (val) {
        if (val.length > 0) {
          this.model = Object.assign({}, this.model, {
            vat_credit_debit: val[0].vat_credit_debit,
            balance_cancelation: val[0].balance_cancelation,
            prefix_for_employee: val[0].prefix_for_employee,
            prefix_for_customer: val[0].prefix_for_customer,
            prefix_for_supplier: val[0].prefix_for_supplier,
            reference_date_limit_from: val[0].reference_date_limit_from,
            reference_date_limit_untill: val[0].reference_date_limit_untill,
            reference_two_date_limit_from: val[0].reference_two_date_limit_from,
            reference_two_date_limit_untill: val[0].reference_two_date_limit_untill,
            bank_notes_date_limit_from: val[0].bank_notes_date_limit_from,
            bank_notes_date_limit_untill: val[0].bank_notes_date_limit_untill
          });
        }
      },
      deep: true
    },
    formattedStartDate() {
      this.model.reference_date_limit_from = this.profileById.date_format ? this.formatDateDDMMYYYY(this.formattedStartDate) : this.formattedStartDate;
    },
    formattedEndDate() {
      this.model.reference_date_limit_untill = this.profileById.date_format ? this.formatDateDDMMYYYY(this.formattedEndDate) : this.formattedEndDate;
    },
    formattedStartDateFor2() {
      this.model.reference_two_date_limit_from = this.profileById.date_format ? this.formatDateDDMMYYYY(this.formattedStartDateFor2) : this.formattedStartDateFor2;
    },
    formattedEndDateFor2() {
      this.model.reference_two_date_limit_untill = this.profileById.date_format ? this.formatDateDDMMYYYY(this.formattedEndDateFor2) : this.formattedEndDateFor2;
    },
    formattedStartDateFor3() {
      this.model.bank_notes_date_limit_from = this.profileById.date_format ? this.formatDateDDMMYYYY(this.formattedStartDateFor3) : this.formattedStartDateFor3;
    },
    formattedEndDateFor3() {
      this.model.bank_notes_date_limit_untill = this.profileById.date_format ? this.formatDateDDMMYYYY(this.formattedEndDateFor3) : this.formattedEndDateFor3;
    },
    e6() {
      if (this.valid) {
        this.alert = false;
      } else {
        this.message = "formRules.formAlert";
        this.alert = true;
      }
    },
  },
  async mounted() {
    this.onMountUpdate()
  },
  methods: {
    parseDateYYYYMMDD:parseDateYYYYMMDD,
    formatDateDDMMYYYY: formatDateDDMMYYYY,
    todayDate: todayDate,
    async onMountUpdate() {
      await this.$store.dispatch('profile/GetCompanyById');
      await this.$store.dispatch('miscellaneous/GetComplusoryNumbers')
      if (this.model.reference_date_limit_from) {
        this.formattedStartDate = new Date(this.model.reference_date_limit_from)
          .toISOString()
          .substr(0, 10);
        this.model.reference_date_limit_from = this.profileById.date_format
          ? this.formatDateDDMMYYYY(this.model.reference_date_limit_from)
          : new Date(this.model.reference_date_limit_from).toISOString().substr(0, 10);
      }
      if (this.model.reference_date_limit_untill) {
        this.formattedEndDate = new Date(this.model.reference_date_limit_untill)
          .toISOString()
          .substr(0, 10);
        this.model.reference_date_limit_untill = this.profileById.date_format
          ? this.formatDateDDMMYYYY(this.model.reference_date_limit_untill)
          : new Date(this.model.reference_date_limit_untill).toISOString().substr(0, 10);
      }
      if (this.model.reference_two_date_limit_from) {
        this.formattedStartDateFor2 = new Date(this.model.reference_two_date_limit_from)
          .toISOString()
          .substr(0, 10);
        this.model.reference_two_date_limit_from = this.profileById.date_format
          ? this.formatDateDDMMYYYY(this.model.reference_two_date_limit_from)
          : new Date(this.model.reference_two_date_limit_from).toISOString().substr(0, 10);
      }
      if (this.model.reference_two_date_limit_untill) {
        this.formattedEndDateFor2 = new Date(this.model.reference_two_date_limit_untill)
          .toISOString()
          .substr(0, 10);
        this.model.reference_two_date_limit_untill = this.profileById.date_format
          ? this.formatDateDDMMYYYY(this.model.reference_two_date_limit_untill)
          : new Date(this.model.reference_two_date_limit_untill).toISOString().substr(0, 10);
      }
      if (this.model.bank_notes_date_limit_from) {
        this.formattedStartDateFor3 = new Date(this.model.bank_notes_date_limit_from)
          .toISOString()
          .substr(0, 10);
        this.model.bank_notes_date_limit_from = this.profileById.date_format
          ? this.formatDateDDMMYYYY(this.model.bank_notes_date_limit_from)
          : new Date(this.model.bank_notes_date_limit_from).toISOString().substr(0, 10);
      }
      if (this.model.bank_notes_date_limit_untill) {
        this.formattedEndDateFor3 = new Date(this.model.bank_notes_date_limit_untill)
          .toISOString()
          .substr(0, 10);
        this.model.bank_notes_date_limit_untill = this.profileById.date_format
          ? this.formatDateDDMMYYYY(this.model.bank_notes_date_limit_untill)
          : new Date(this.model.bank_notes_date_limit_untill).toISOString().substr(0, 10);
      }
    },
    async saveComplusoryNumbers() {
      this.loading = true;
      this.model.reference_date_limit_from = this.profileById.date_format ? this.parseDateYYYYMMDD(this.model.reference_date_limit_from) : this.model.reference_date_limit_from
      this.model.reference_date_limit_untill = this.profileById.date_format ? this.parseDateYYYYMMDD(this.model.reference_date_limit_untill) : this.model.reference_date_limit_untill
      this.model.reference_two_date_limit_from = this.profileById.date_format ? this.parseDateYYYYMMDD(this.model.reference_two_date_limit_from) : this.model.reference_two_date_limit_from
      this.model.reference_two_date_limit_untill = this.profileById.date_format ? this.parseDateYYYYMMDD(this.model.reference_two_date_limit_untill) : this.model.reference_two_date_limit_untill
      this.model.bank_notes_date_limit_from = this.profileById.date_format ? this.parseDateYYYYMMDD(this.model.bank_notes_date_limit_from) : this.model.bank_notes_date_limit_from
      this.model.bank_notes_date_limit_untill = this.profileById.date_format ? this.parseDateYYYYMMDD(this.model.bank_notes_date_limit_untill) : this.model.bank_notes_date_limit_untill
      await this.$store.dispatch('miscellaneous/SetComplusoryNumbers', this.model)
      this.onMountUpdate()
      this.loading = false;
    },
    accordionStepper(current) {
      if (this.previousStep == current && this.previousStep != 4) {
        this.e6 = 4;
        this.previousStep = 4;
      } else {
        this.previousStep = current;
      }
    },
  },
};
</script>